import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";

// Custom Components
import SoftBox from "components/SoftBox";

import SoftTypography from "components/SoftTypography";

import PageLayout from "examples/LayoutContainers/PageLayout";
import Select from "react-select";
import { OutlinedInput } from "@mui/material";
import { Tooltip } from "antd";
import add from "../../assets/images/add1.svg";

// Utilities

import {
  borderRadius,
  color,
  display,
  fontSize,
  fontWeight,
  height,
  maxWidth,
  minHeight,
  spacing,
  textAlign,
  textTransform,
  width,
} from "@mui/system";
import Swal from "sweetalert2";
import Chip from "@mui/material/Chip";

// Images
import image from "../../assets/FAQ.png";
import location1 from "assets/location (2) 1.svg";
import logo from "../../assets/indus.png";
import amex_logo from "../../assets/business-and-trade (6) 1.svg";
import Jd from "../../assets/Job Details.png";
import jobdesp from "../../assets/Job Description.png";
import { useMediaQuery } from "@mui/material";

import axios from "axios";
import { Grid } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import moment from "moment";

const stylesDD = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    // borderColor: state.isFocused ? "grey" : "red",
    fontSize: "14px",
  }),
  option: (base) => ({
    ...base,
    // border: `1px dotted red`,
    height: "100%",
    fontSize: "14px",
  }),
};

const Skilling = () => {
  const location = useLocation();
  const [referral_id, setReferral_id] = useState(null);
  const [referee_id, setReferee_id] = useState(null);
  const [courseid, setCourseid] = useState(null);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ref1 = queryParams.get("referral_id");
    const ref2 = queryParams.get("referee_id");
    const courseid = queryParams.get("course_id");
    console.log("jjj", ref1);
    if (ref1) {
      ref1 !== "null" && ref1 !== "" && setReferral_id(ref1);
    }
    if (ref2) {
      ref2 !== "null" && ref2 !== "" && setReferee_id(ref2);
    }
    if (courseid) {
      courseid !== "null" && courseid !== "" && setCourseid(courseid);
    }

    // setCourseid(queryParams.get("courseid"));
    // setReferee_id(queryParams.get("referee_id"));
  }, [location.search]);
  console.log(referee_id, "111", referral_id, courseid);
  const navigate = useNavigate();
  // let { referral_id, courseid, referee_id, source } = useParams();
  // let { courseid, source } = useParams();
  const matches = useMediaQuery("(min-width:800px)");
  const transparent = false;
  const light = false;
  const [OTP, setOTP] = useState({
    mobileno: "",
    otp: "",
  });

  const [jdData, setJdData] = useState({
    designation: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [loginEmail, setLoginEmail] = useState({
    email: "",
    password: "",
  });

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/get_course_detail?courseid=${courseid}`
  //     )
  //     .then((res) => {
  //       console.log(res.data.course_data, "course");
  //       setJdData(res.data.course_data);
  //     })
  //     .catch((err) => {});
  // }, []);

  // {
  //   jdData, "ooo";
  // }

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.history.pushState(null, null, window.location.pathname);
  };

  window.addEventListener("popstate", onBackButtonEvent);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  useEffect(() => {
    courseid &&
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/get_course_detail?courseid=${courseid}`
        )
        .then((res) => {
          console.log(res.data.course_data, "omkar");
          setJdData(res.data.course_data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [courseid]);

  ///...................Fetch Referee..............//
  const [referee, setReferee] = useState({
    name: "",
    phone: "",
  });
  const [newRefId, setRefId] = useState(null);

  const fetchReferee = async () => {
    console.log(referee, "hello");
    if (referee.name == "" || referee.phone == "") {
      Swal.fire({
        title: "Name and Phone number required",
        icon: "info",
      });
    }
    await axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/skilling/referral/create-referree/`, {
        name: referee.name,
        mobile: referee.phone,
        referral_id: referral_id,
        course_id: courseid,
      })
      .then((res) => {
        console.log(res.data.referree_id);
        setRefId(res.data.referree_id);
        Swal.fire({
          title: "Referee created successfully",
          icon: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Something went wrong",
          icon: "error",
        });
      });
  };

  console.log("111", newRefId);
  // useEffect(() => {
  //   fetchReferee();
  // }, []);

  ///.................Add Referee.............
  const [refCands, setRefCands] = useState([{ name: "", phone: "", id: 1 }]);
  const [refCands2, setRefCands2] = useState([{ name: "", phone: "", id: 1 }]);
  const [screen, setScreen] = useState(1);
  // const [candidate]

  const handleAddCand = () => {
    setRefCands([...refCands, { name: "", phone: "", id: refCands.length + 1 }]);
  };

  const [referral, setReferral] = useState("");
  const handleAddCand2 = () => {
    setRefCands2([...refCands2, { job: "", name: "", phone: "", id: refCands2.length + 1 }]);
  };
  const handleInputChange = (e, id) => {
    const newFields = refCands.map((el, i) => {
      //   console.log(field, "hello");
      console.log("hello", el.id, id, el);
      if (el.id === id) {
        return { ...el, [e.target.name]: e.target.value };
      }
      return el;
    });
    setRefCands(newFields);
  };
  const handleInputChange2 = (e, id) => {
    console.log(e, "hello");
    const newFields = refCands2.map((el, i) => {
      if (el.id === id) {
        return e.label
          ? { ...el, job: { label: e.value, value: e.value } }
          : { ...el, [e.target.name]: e.target.value };
      }
      return el;
    });
    setRefCands2(newFields);
  };

  const handleDelete = (index) => {
    console.log(index);
    const newFields = refCands.filter((_, i) => {
      // i !== index && console.log(i, index);
      return i !== index;
    });
    setRefCands(newFields);
  };

  const [jobList, setJobList] = useState([]);
  console.log(jobList);
  const fetchReferralJob = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/skilling/referral/active-referrals`, {
        params: {
          // type: "dropdown",
          // source: "referral",
        },
      })
      .then((res) => {
        setJobList(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchReferralJob();
  }, []);

  const handleLogin = async () => {
    setIsLoading(true);

    var formData = new FormData();
    formData.append("email", loginEmail.email);
    formData.append("password", loginEmail.password);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL_AUTH}/login_page/`,
        // .post("https://career.guidence.middleware.uat.globalsapio.com/authentication/signin/", {
        {
          email: loginEmail.email,
          password: loginEmail.password,
        }
        // formData
      )
      .then((res) => {
        if (res.status === 200) {
          // const role = res.data.user_type_id;
          // sessionStorage.setItem("email", res.data["email"]);
          // sessionStorage.setItem("user_name", res.data["user_name"]);
          sessionStorage.setItem("role_type", res.data.role_type);
          sessionStorage.setItem("role_id", res.data.role_id);
          sessionStorage.setItem("user_id", res.data.user_id);
          sessionStorage.setItem("access_token", res.data.access);
          sessionStorage.setItem("refresh_token", res.data.refresh);
          sessionStorage.setItem("username", res.data.username);

          console.log("resData", res);

          navigate("/defaulter");
        } else {
          Swal.fire({
            position: "top",
            icon: "warning",
            title: "Something went wrong!",
            showConfirmButton: false,
            timer: 3000,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#18838d",
            timer: 3000,
            title: "Invalid credentials",
            // text: "Invalid credentials",
          });
        } else {
          Swal.fire({
            position: "top",
            icon: "warning",
            title: "Something went wrong!",
            showConfirmButton: false,
            timer: 3000,
          });
        }
        console.log(err);
        setIsLoading(false);
      });
    // }
  };

  const handleRedirect = async (event) => {
    event.preventDefault();
    // window.location.href = `${process.env.REACT_APP_FORM_URL}/skilling?cpid=&id=null&source=${source}&courseid=${courseid}&orgid=null`;
    console.log(refCands, "hello");
    await axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/skilling/referral/add-candidate`, {
        referral_id: referral_id,
        referree_id: referee_id || newRefId,
        course_id: courseid,
        candidates: refCands.map((el) => {
          return { name: el.name, mobile: el.phone };
        }),
      })
      .then((res) => {
        console.log(res.data);
        setRefCands([{ name: "", phone: "", id: 1 }]);
        Swal.fire({
          title: res.data.message,
          icon: "success",
        });
      })
      .catch((res, err) => {
        console.log(err, res, "11");
        Swal.fire({
          title: "Something went wrong",
          icon: "error",
        });
      });
  };

  const handleRedirect2 = async (event) => {
    event.preventDefault();
    // window.location.href = `${process.env.REACT_APP_FORM_URL}/skilling?cpid=&id=null&source=${source}&courseid=${courseid}&orgid=null`;
    console.log(refCands, "hello");
    await axios
      .post(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/skilling/referral/add-candidate`, {
        referral_id: "",
        referree_id: referee_id || newRefId,
        course_id: referral.value,
        candidates: refCands2
          .map((el) => {
            return { name: el.name, mobile: el.phone };
          })
          .filter((el) => {
            return el.name !== "" && el.phone !== "";
          }),
      })
      .then((res) => {
        console.log(res.data);
        setRefCands2([{ name: "", phone: "", id: 1 }]);
        Swal.fire({
          title: res.data.message,
          icon: "success",
        });
      })
      .catch((err) => {
        // console.log(err, res, "11");
        Swal.fire({
          title: "Something went wrong",
          icon: "error",
        });
      });
  };

  return (
    // <BasicLayout title="Welcome!" description="Select user type" image={curved6}>
    <>
      <PageLayout>
        <SoftBox
          sx={{
            minHeight: "100vh",
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {screen == 1 ? (
            <SoftBox
              sx={
                matches
                  ? {
                      height: "100%",
                      background: "#FFFFFF",
                      borderRadius: "8px",
                      width: "800px",
                      m: 2,
                      p: 2,
                    }
                  : {
                      height: "100%",
                      background: "#FFFFFF",
                      borderRadius: "8px",
                      maxWidth: "800px",
                      m: 2,
                      p: 2,
                    }
              }
            >
              <Grid container spacing={2}>
                <Grid item lg={2} md={3} sm={3} xs={3}>
                  <SoftBox
                    sx={{
                      p: 2,
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <img
                      src={logo}
                      alt="logo"
                      width="75px"
                      height="72px"
                      style={{ marginTop: "8px" }}
                    />
                  </SoftBox>
                </Grid>

                <Grid item lg={10} md={9} sm={9} xs={9}>
                  <SoftBox sx={{ p: 2, width: "100%" }}>
                    <SoftTypography
                      sx={{ fontSize: "24px", color: "#2F55D0", fontWeight: "700", mb: 1 }}
                    >
                      {jdData.course_title ? jdData.course_title : "-"}
                    </SoftTypography>

                    <SoftBox
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <img
                        src={amex_logo}
                        width="18px"
                        height="18px"
                        style={{ marginRight: "8px" }}
                      />

                      <SoftTypography
                        sx={{ fontSize: "14px", color: "#848383", fontWeight: "500" }}
                      >
                        Organization Name:{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "black",
                            width: "70px",
                          }}
                        >
                          {jdData.org_name ? jdData.org_name : "-"}
                        </span>
                      </SoftTypography>
                    </SoftBox>

                    <SoftBox
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <img
                        src={location1}
                        width="18px"
                        height="18px"
                        style={{ marginRight: "8px" }}
                      />

                      <SoftTypography
                        sx={{ fontSize: "14px", color: "#848383", fontWeight: "500" }}
                      >
                        Location:{" "}
                        <span style={{ fontWeight: "bold", fontSize: "14px", color: "black" }}>
                          {" "}
                          {jdData.district ? jdData.district : "-"}
                        </span>
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>

              <SoftBox sx={{ ml: 3, mr: 3, m: 2 }}>
                <SoftTypography sx={{ fontSize: "16px", fontWeight: "700" }}>
                  Course Details
                </SoftTypography>
                <hr />

                <SoftBox
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                  }}
                >
                  {/* <Chip
                    label={`Course Name: ${jdData.course_title ? jdData.course_title : "-"}`}
                    variant="outlined"
                    sx={{ mr: 1, mb: 1 }}
                  /> */}
                  <Chip
                    label={`Mode of Course : ${
                      jdData.mode_of_course ? jdData.mode_of_course : "-"
                    }`}
                    variant="outlined"
                    sx={{ mr: 1, mb: 1 }}
                  />
                  <Chip
                    label={`Duration :${jdData.duration ? jdData.duration : "-"}`}
                    variant="outlined"
                    sx={{ mr: 1, mb: 1 }}
                  />
                  <Chip
                    label={`Language : ${jdData.language ? jdData.language : "-"}`}
                    variant="outlined"
                    sx={{ mr: 1, mb: 1 }}
                  />
                  <Chip
                    label={`Start Date : ${
                      jdData.start_date ? moment(jdData.start_date).format("MM-DD-yyyy") : "-"
                    } `}
                    variant="outlined"
                    sx={{ mr: 1, mb: 1 }}
                  />
                  <Chip
                    label={`Fees : ${jdData.fees ? jdData.fees : "-"} `}
                    variant="outlined"
                    sx={{ mr: 1, mb: 1 }}
                  />
                </SoftBox>
              </SoftBox>

              <SoftBox sx={{ ml: 3, mr: 3, m: 2 }}>
                <SoftTypography sx={{ fontSize: "16px", fontWeight: "700" }}>
                  Course Description
                </SoftTypography>
                <hr />

                <SoftTypography
                  dangerouslySetInnerHTML={{ __html: jdData.description }}
                  sx={{ fontSize: "16px", color: "#000000" }}
                />
              </SoftBox>

              {referee_id == null && newRefId == null && (
                <SoftBox m={2}>
                  <SoftTypography sx={{ fontSize: "16px", fontWeight: 700 }}>
                    Add Your Details
                  </SoftTypography>
                  <hr />
                  <SoftBox
                    // key={i}
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      // alignItems:"center",
                      gap: "2%",
                      my: 2,
                    }}
                  >
                    <SoftBox sx={{ width: { xs: "100%", md: "48%" } }}>
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        Name
                      </SoftTypography>
                      <SoftInput
                        styles={stylesDD}
                        placeholder="Enter Name"
                        name="name"
                        value={referee.name}
                        onChange={(e) => {
                          setReferee({ ...referee, name: e.target.value });
                        }}
                      />

                      {/* <OutlinedInput value={"HII"} disabled></OutlinedInput> */}
                    </SoftBox>
                    <SoftBox
                      sx={{ width: { xs: "100%", md: "48%" }, mt: { xs: "20px", md: "0px" } }}
                    >
                      <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                        Phone Number
                      </SoftTypography>
                      <SoftInput
                        styles={stylesDD}
                        type="number"
                        placeholder="Enter Phone Number"
                        name="phone"
                        value={referee.phone}
                        onChange={(e) => {
                          setReferee({ ...referee, phone: e.target.value });
                        }}
                      />
                      {/* <OutlinedInput value={"HII"} disabled></OutlinedInput> */}
                    </SoftBox>
                    {/* {i !== 0 && (
                      <SoftTypography
                        sx={{ color: "red", fontSize: "16px", cursor: "pointer", fontWeight: 600 }}
                        onClick={() => handleDelete(i)}
                      >
                        x
                      </SoftTypography>
                    )} */}
                  </SoftBox>
                </SoftBox>
              )}
              {(referee_id !== null || newRefId !== null) && (
                <SoftBox m={2}>
                  {/* <SoftBox sx={{ display: "flex", justifyContent: "end" }}>
                    <Tooltip key="6" title={`Add more candidates `} placement="left">
                      <SoftBox sx={{ cursor: "pointer" }} onClick={handleAddCand}>
                        <img src={add} alt="Add" />
                      </SoftBox>
                    </Tooltip>
                  </SoftBox> */}
                  <SoftBox>
                    <SoftTypography sx={{ fontSize: "16px", fontWeight: 700 }}>
                      Add Candidates
                    </SoftTypography>
                  </SoftBox>
                  {refCands.map((el, i) => {
                    return (
                      <SoftBox
                        key={i}
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", md: "row" },
                          // alignItems:"center",
                          gap: "2%",
                          my: 2,
                        }}
                      >
                        <SoftBox sx={{ width: { xs: "100%", md: "48%" } }}>
                          <SoftTypography
                            fontWeight="medium"
                            fontSize="16px"
                            lineHeight="20px"
                            mb={1}
                          >
                            Name
                          </SoftTypography>
                          <SoftInput
                            styles={stylesDD}
                            placeholder="Enter Name"
                            name="name"
                            value={el.name}
                            onChange={(e) => {
                              handleInputChange(e, el.id);
                            }}
                          />

                          {/* <OutlinedInput value={"HII"} disabled></OutlinedInput> */}
                        </SoftBox>
                        <SoftBox
                          sx={{ width: { xs: "100%", md: "48%" }, mt: { xs: "20px", md: "0px" } }}
                        >
                          <SoftTypography
                            fontWeight="medium"
                            fontSize="16px"
                            lineHeight="20px"
                            mb={1}
                          >
                            Phone Number
                          </SoftTypography>
                          <SoftInput
                            styles={stylesDD}
                            type="number"
                            placeholder="Enter Phone Number"
                            name="phone"
                            value={el.phone}
                            onChange={(e) => {
                              handleInputChange(e, el.id);
                            }}
                          />
                          {/* <OutlinedInput value={"HII"} disabled></OutlinedInput> */}
                        </SoftBox>
                        {/* {i !== 0 && (
                      <SoftTypography
                        sx={{ color: "red", fontSize: "16px", cursor: "pointer", fontWeight: 600 }}
                        onClick={() => handleDelete(i)}
                      >
                        x
                      </SoftTypography>
                    )} */}
                      </SoftBox>
                    );
                  })}
                  <SoftBox
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "#CCE6FF33",
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      border: "1px dashed #317BFF",
                      py: 1,
                      px: 4,
                      m: 2,
                      cursor: "pointer",
                    }}
                    onClick={() => handleAddCand()}
                  >
                    {/* <SoftBox sx={{backgroundColor:"blue", height:"30px", width:"30px", borderRadius:"50%"}}></SoftBox> */}
                    <img src={add} alt="Add" />
                    <SoftTypography sx={{ color: "#0072FF" }}>Add more candidate</SoftTypography>
                  </SoftBox>
                </SoftBox>
              )}

              {referee_id !== null || newRefId !== null ? (
                <SoftBox
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  margin="8px"
                  gap="16px"
                >
                  <a
                    onClick={handleRedirect}
                    // href={`${process.env.REACT_APP_FORM_URL}/jobs?cpid=&id=null&source=null&courseid=${courseid}&orgid=null`}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        width: "120px",
                        height: "40px",
                        borderRadius: "6px",
                        background: "#2D60FF",
                        marginTop: "2rem",
                        cursor: "pointer",
                      }}
                    >
                      <SoftTypography sx={{ color: "#fff", fontSize: "15px", fontWeight: "700" }}>
                        Refer now
                      </SoftTypography>
                    </SoftBox>
                  </a>

                  <SoftTypography
                    sx={{
                      color: "#317BFF",
                      fontSize: "15px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setScreen(2)}
                  >
                    Refer for another course
                  </SoftTypography>
                </SoftBox>
              ) : (
                <SoftBox
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  margin="8px"
                  gap="16px"
                >
                  <a
                    onClick={() => referee.name !== "" && referee.phone !== "" && fetchReferee()}
                    // href={`${process.env.REACT_APP_FORM_URL}/jobs?cpid=&id=null&source=null&courseid=${courseid}&orgid=null`}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        width: "140px",
                        height: "40px",
                        borderRadius: "6px",
                        background: "#2D60FF",
                        marginTop: "2rem",
                        cursor: referee.name !== "" && referee.phone !== "" ? "pointer" : "no-drop",
                      }}
                    >
                      <SoftTypography sx={{ color: "#fff", fontSize: "15px", fontWeight: "700" }}>
                        Refer now
                      </SoftTypography>
                    </SoftBox>
                  </a>
                </SoftBox>
              )}
            </SoftBox>
          ) : (
            <SoftBox
              sx={
                matches
                  ? {
                      height: "100%",
                      background: "#FFFFFF",
                      borderRadius: "8px",
                      width: "800px",
                      m: 3,
                      p: 2,
                    }
                  : {
                      height: "100%",
                      background: "#FFFFFF",
                      borderRadius: "8px",
                      maxWidth: "800px",
                      m: 3,
                      p: 2,
                    }
              }
            >
              <SoftBox sx={{ display: "flex", justifyContent: "space-between", px: 4, py: 2 }}>
                <SoftBox
                  sx={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }}
                  onClick={() => {
                    setScreen(1);
                  }}
                >
                  <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 13L1 7L7 1"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <SoftTypography sx={{ fontSize: "16px", fontWeight: 700 }}>Back</SoftTypography>
                </SoftBox>
                <SoftBox>
                  <SoftTypography sx={{ fontSize: "24px", fontWeight: 700 }}>
                    Refer Candidate
                  </SoftTypography>
                </SoftBox>
                <SoftBox></SoftBox>
              </SoftBox>

              <SoftBox px={2} m={2}>
                <SoftTypography fontWeight="medium" fontSize="16px" lineHeight="20px" mb={1}>
                  Active Referral
                </SoftTypography>
                <SoftBox
                  bgColor="white"
                  // sx={{ backgroundColor: "white" }}
                >
                  <SoftSelect
                    style={{ backgroundColor: "white" }}
                    placeholder="Select Course"
                    name="job"
                    value={referral}
                    onChange={(e) => {
                      setReferral(e);
                    }}
                    options={jobList}
                  />
                </SoftBox>
              </SoftBox>
              {refCands2.map((el, i) => {
                return (
                  <SoftBox
                    key={i}
                    px={4}
                    py={2}
                    my={1}
                    sx={{ borderRadius: 2, backgroundColor: "#CCE6FF33", mx: 2 }}
                  >
                    <SoftBox
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        gap: "2%",
                        my: 2,
                      }}
                    >
                      <SoftBox sx={{ width: { xs: "100%", md: "48%" } }}>
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          Name
                        </SoftTypography>
                        <SoftInput
                          styles={stylesDD}
                          placeholder="Enter Name"
                          name="name"
                          value={el.name}
                          onChange={(e) => {
                            handleInputChange2(e, el.id);
                          }}
                        />
                      </SoftBox>
                      <SoftBox
                        sx={{ width: { xs: "100%", md: "48%" }, mt: { xs: "20px", md: "0px" } }}
                      >
                        <SoftTypography
                          fontWeight="medium"
                          fontSize="16px"
                          lineHeight="20px"
                          mb={1}
                        >
                          Phone Number
                        </SoftTypography>
                        <SoftInput
                          styles={stylesDD}
                          type="number"
                          placeholder="Enter Phone Number"
                          name="phone"
                          value={el.phone}
                          onChange={(e) => {
                            handleInputChange2(e, el.id);
                          }}
                        />
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                );
              })}
              <SoftBox
                sx={{
                  borderRadius: 2,
                  backgroundColor: "#CCE6FF33",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  border: "1px dashed #317BFF",
                  py: 1,
                  px: 4,
                  m: 2,
                  cursor: "pointer",
                }}
                onClick={() => handleAddCand2()}
              >
                {/* <SoftBox sx={{backgroundColor:"blue", height:"30px", width:"30px", borderRadius:"50%"}}></SoftBox> */}
                <img src={add} alt="Add" />
                <SoftTypography sx={{ color: "#0072FF" }}>Add candidate</SoftTypography>
              </SoftBox>
              {
                <SoftBox
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  margin="8px"
                  gap="16px"
                >
                  <a
                    onClick={handleRedirect2}
                    // href={`${process.env.REACT_APP_FORM_URL}/jobs?cpid=&id=null&source=null&courseid=${courseid}&orgid=null`}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        width: "120px",
                        height: "40px",
                        borderRadius: "6px",
                        background: "#2D60FF",
                        marginTop: "2rem",
                        cursor: "pointer",
                      }}
                    >
                      <SoftTypography sx={{ color: "#fff", fontSize: "15px", fontWeight: "700" }}>
                        Refer now
                      </SoftTypography>
                    </SoftBox>
                  </a>
                </SoftBox>
              }
            </SoftBox>
          )}
        </SoftBox>
      </PageLayout>
    </>
  );
};

export default Skilling;
