const INITIAL_STATE = {
  userProfile: {},
};

const UserProfile = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_USER_PROFILE":
      return {
        ...state,
        userProfile: action.payload,
      };

    case "REMOVE_USER_PROFILE":
      return {
        ...state,
        userProfile: {},
      };

    default:
      return state;
  }
};

export default UserProfile;
