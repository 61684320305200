const INITIAL_STATE = {
  option: "Textile and Clothing",
};

const MiddleLayerSelection = ( state = INITIAL_STATE, action ) => {
    switch (action.type){
        case 'SET_MLF':
            return {
                ...state,
                option: action.payload
            }

        case 'REMOVE_MLF':
            return {
                ...state,
                option: ''
            }

        default : return state;
    }
};

export default MiddleLayerSelection;