
import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// import { withStyles } from "@material-ui/core/styles";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Custom styles for the SidenavItem
import { item, itemContent, itemArrow } from "examples/Sidenav/styles/sidenavItem";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from "context";
import { useState } from "react";



function SidenavItem({ name, active, nested, children, open ,...rest }) {
  const [controller] = useSoftUIController();
  const { miniSidenav } = controller;
  const [currMaster,setCurrMaster] = useState(false);
  const onClickItem = ()=>{ 
    setCurrMaster(!currMaster);    
    // console.log("item",item);
  }
    // console.log("state",done);
    // console.log("rest",...rest);
    console.log("item",item);
  // useEffect(()=>{
  //   setCurrMaster(false)
  // },[currMaster])
  return (
    <>
      {/* sx={item} */}
      <ListItem {...rest} component="li" 
        sx={item}
          // onClick={onClickItem}
          // className={done ? classes.listItemDone : classes.listItem}
          // sx={{...item,backgroundColor : currMaster ? "#17c1e8" : "#FFFFFF" , color: currMaster ? "#FFF" : "#000000"}}
          >
        <SoftBox sx={(theme) => itemContent(theme, { active, miniSidenav, name, nested })}>
          <ListItemText primary={name} />
          {/* {children && (
            <Icon component="i" sx={(theme) => itemArrow(theme, { open, miniSidenav })}>
              expand_less
            </Icon>
          )} */}
        </SoftBox>
      </ListItem>
      {/* {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )} */}
    </>
  );
}

// Setting default values for the props of SidenavItem
SidenavItem.defaultProps = {
  active: false,
  nested: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavItem
SidenavItem.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  nested: PropTypes.bool,
  children: PropTypes.node,
  open: PropTypes.bool,
};

export default SidenavItem;
